//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Navigation } from 'swiper';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';

Swiper.use([Navigation]);

export default {
  name: 'HeroChannels',
  mixins: [channelTypesMixin],
  components: {
    ProductIcon: () => import('@motionelements/core/src/components/product/ProductIcon.vue'),
  },
  data() {
    return {
      faAngleLeft,
      faAngleRight,
      swiper: null,
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.testimonialsSwiper, {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 0,
      preventClicks: false,
      preventClicksPropagation: false,
      loop: false,
      autoplay: false,
      allowTouchMove: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        575: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
        768: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
        991: {
          slidesPerView: 6,
          slidesPerGroup: 6,
          allowTouchMove: true,
        },
        1200: {
          slidesPerView: 7,
          slidesPerGroup: 7,
        },
        1600: {
          slidesPerView: 10,
          slidesPerGroup: 10,
        },
      },
    });
  },
  beforeDestroy() {
    if (this.swiper && this.swiper.el) {
      this.swiper.destroy();
    }
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper(this.$refs.testimonialsSwiper, {
        slidesPerView: 'auto',
        spaceBetween: 0,
        loop: false,
        autoplay: false,
      });
    },
  },
};
